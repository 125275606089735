.header {
    @media (min-width: 993px) {
        overflow: hidden;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &.open  {
        & .menu {
            &::before {
                z-index: 12 !important;
            }
        }
    }
    & .menu {
        @media(max-width: 992px) {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 11;
            width: 100%;
            max-width: 210px;
            display: none;
            box-shadow: 0 0 30px rgba(34, 34, 42, 0.3);
        }
        @media(max-height: 500px) {
            overflow: scroll;
            height: 100vh;
            padding-bottom: 80px;
            background-color: #007dd7;
        }
        & .logo-wrapper {
            position: relative;
            @media(max-width: 992px) {display: none;}
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: calc(100% - 16px);
                width: 100%;
                max-width: 900px;
                height: 127px;
                background-color: #ebebeb;
            }
        }
        & .header-info {
            @media(max-width: 992px) {display: none;}
        }
    }
    &-menu {
        &-logo {
            position: absolute;
            top: 0;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            max-width: 279px;
            height: 127px;
            background-color: #ebebeb;
            clip-path: polygon(0 0, 83% 0, 100% 100%, 0% 100%);
            & img {max-width: 211px;}
        }
    }
    &-top {
        & .header-mobile-block {
            display: none;
            @media(max-width: 992px) {
                display: flex;
                margin-bottom: 35px;
                & .header-info {display: block;}
            }
        }
        padding: 20px 0;
        background-color: #ebebeb;
        @media(max-width: 992px) {
            width: 100%;
            padding: 41px 0 20px;
        }
        & .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 992px) {
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
            }
        }
        &-links {
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width: 992px) {
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
            }
            & a {
                position: relative;
                display: block;
                font-family: 'OpenSans-Bold';
                font-size: 18px;
                line-height: 18px;
                color: #22222a;
                @media(max-width: 992px) {
                    font-size: 16px;
                    line-height: 16px;
                    text-align: right;
                }
                &:not(:last-child) {
                    margin-right: 25px;
                    @media(max-width: 1024px) {margin-right: 15px;}
                    @media(max-width: 992px) {margin: 0 0 23px;}
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: calc(100% + 8px);
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: #007dd7;
                    opacity: 0;
                    transition: 0.3s ease;
                    @media(max-width: 992px) {display: none;}
                }
                &:hover,
                &.active {
                    color: #007dd7;
                    &::before {opacity: 1;}
                }
            }
        }
    }
    &-bottom {
        padding: 24px 0;
        background-color: #007DD7;
        @media(max-width: 992px) {
            width: 100%;
            padding: 20px 0;
        }
        & .wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        & nav {
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width: 992px) {
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
            }
            & a {
                position: relative;
                display: block;
                padding: 0 3px;
                font-family: 'OpenSans-Bold';
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                @media(max-width: 992px) {
                    font-size: 16px;
                    line-height: 16px;
                    text-align: right;
                }
                &:not(:last-child) {
                    margin-right: 26px;
                    @media(max-width: 1024px) {margin-right: 15px;}
                    @media(max-width: 992px) {margin: 0 0 23px;}
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: calc(100% + 5px);
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: #ffffff;
                    opacity: 0;
                    transition: 0.3s ease;
                    @media(max-width: 992px) {display: none;}
                }
                &.exit {
                    margin-left: 36px;
                    padding: 0;
                    @media(max-width: 992px) {
                        margin: 0 !important;
                    }
                }
                &:hover,
                &.active {
                    &::before {opacity: 1;}
                }
            }
        }
    }
    &-info {
        padding-left: 270px;
        font-family: 'OpenSans-Bold';
        font-size: 21px;
        line-height: 21px;
        color: #007dd7;
        @media(max-width: 992px) {padding-left: 0;}
    }
    &-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        padding: 23px 15px;
        background-color: #f7f7f7;
        @media(max-width: 992px) {display: flex;}
        & .logo {
            width: 100%;
            max-width: 119px;
            & img {width: 100%;}
        }
        &-block {
            display: flex;
            align-items: center;
            justify-content: center;
            & .header-info {margin-right: 29px;}
        }
    }
}


.menu-trigger {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    width: 28px;
    height: 22px;
    transition: 0.2s ease;
    & span {
        display: block;
        width: 100%;
        height: 6px;
        background-color: #007dd7;
        border-radius: 8px;
        transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
    &-close {
        justify-content: center;
        & span {
            position: relative;
            width: 100%;
            background-color: #B8B8B8;
            &:nth-child(1) {
                transform: rotate(-40deg);
                margin-bottom: -6px;
            }
            &:nth-child(2) {display: none;}
            &:nth-child(3) {transform: rotate(40deg);}
        }
    }
}
