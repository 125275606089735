html,
body {
	height: 100%;
}

body {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;
    @media(max-width: 992px) {
        &:not(.error-page-block) {background: #ffffff !important;}
    }
    &.error-page-block {
        background-image: url('../images/background.jpg') !important;
        background-position: center;
    }
    &.fixed {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
.section {
    padding-bottom: 74px;
    &.section-news {padding-bottom: 45px;}
    &.section-personnel {padding-bottom: 33px;}
    &.section-contacts {padding-bottom: 56px;}
    @media(max-width: 767px){
        padding-bottom: 50px;
    }
}

.wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.main { 
    display: flex;
	flex-direction: column;
    min-height: 100%;
    &-links {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        @media(max-width: 1140px) {
            padding: 20px 0 35px;
        }
        & a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            max-width: 50%;
            height: 360px;
            padding: 15px 15px 15px 50px;
            font-family: 'OpenSans-Bold';
            font-size: 32px;
            line-height: 32px;
            color: #22222a;
            transition: 0.3s ease;
            border: 1px solid #ebebeb;
            background-color: #ffffff;
            background-repeat: no-repeat;
            background-position: bottom -2px right 0;
            @media(max-width: 767px) {
                max-width: 100%;
                height: 138px;
                padding-left: 25px;
                font-size: 30px;
                line-height: 30px;
                &.main-link-1 {background-size: 74px 101px;}
                &.main-link-2 {background-size: 63px 94px;}
                &.main-link-3 {background-size: 77px 93px;}
                &.main-link-4 {background-size: 63px 98px;}
                &:not(:last-child) {border-bottom: none;}
            }
            @media(min-width: 1100px) {
                &:hover {
                    z-index: 2;
                    color: #007dd7;
                    box-shadow: 0 0 30px rgba(34, 34, 42, 0.3);
                }
            }

        }
    }
    .wrapper {
        flex: 1 0 auto;
        margin-bottom: 108px;
        padding-top: 28px;
        @media(max-width: 1140px) {
            margin-bottom: 0;
		    box-shadow: none;
        }
        @media(max-width: 767px) {
            padding-top: 15px;
        }
    }
}


.section-title { 
    display: block;
    margin-bottom: 60px; 
    font-family: 'OpenSans-Bold';
    font-size: 42px;
    line-height: 42px;
    color: #33379d;
    @media(max-width: 992px) {
        margin-bottom: 35px;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
    }
}

.section-title-small { 
    display: block;
    margin-bottom: 22px;
    font-family: 'OpenSans-Bold';
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    color: #007dd7;
    @media(max-width: 992px) { text-align: center;}
}


.mb-44 {margin-bottom: 44px;}


.form-field{
    display: block;
    width: 100%;
    height: 47px;
    padding: 13px 18px 11px;
    font-size: 16px;
    line-height: 18px;
    font-family: 'OpenSans-Light';
    color: #898989;
    border: 1px solid #ebebeb;
    background: #ffffff;
    border-radius: 23px;
    transition: 0.3s ease;
    -ms-appearance: none;
    appearance: none!important;
    -moz-appearance: none;
    -webkit-appearance: none;
    white-space: nowrap;
    word-wrap: normal;
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder
    &::-moz-placeholder {
        padding: 11px 18px 13px;
        font-size: 16px;
        line-height: 18px;
    }
    &:hover,
    &:focus {
        border: 1px solid #007dd7;
        color: #007dd7;
    }
    &.date-field {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 46px;
        padding: 11px 18px 13px;
        font-size: 16px;
        line-height: 18px;
    }
}
textarea.form-field {padding-top: 20px;}


.tabs-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    @media(max-width: 767px) {
        flex-flow: row wrap;
        justify-content: space-around;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 220px;
        height: 56px;
        font-family: 'OpenSans-Bold';
        font-size: 14px;
        line-height: 14px;
        color: #4f4f4f;
        background-color: #e1e1e1;
        transition: 0.3s ease;
        @media(max-width: 900px) {
            font-size: 12px;
            line-height: 12px;
        }
        &:not(:last-child) {margin-right: 1px;}
        &.active,
        &:hover {
            color: #ffffff;
            background-color: #5199c9;
        }
        @media(max-width: 767px) {
            max-width: calc(50% - 10px);
            &:not(:last-child) {margin-right: 0px;} 
            margin-bottom: 5px;
        }
        @media(max-width: 500px) {
            max-width: 100%;
            margin-bottom: 5px;
        }
    }
}



@media(max-width: 722px) {
    .paragraph {width: 100%;}
    .wp-block-table {
      width: 100%;
      overflow: scroll;
    }
    .wp-block-table table {width: 682px;}
  }

.tab-cnt {
    display: none;
    &.active {display: block;}
}

.w-1100 {width: 1100px;}
.w-770 {width: 770px;}
.w-220 {width: 220px;}
.w-50 {width: 50px;}
.w-46 {width: 46px;}
.w-25p {width: 25%;}
.w-100p {width: 100%;}
.h-56 {height: 56px;}
.h-50 {height: 50px;}
.h-45 {height: 45px;}
.h-42 {height: 42px;}
.h-40 {height: 40px;}
.h-38 {height: 38px;}

.t-align-center {text-align: center;}
.t-align-left {text-align: left;}
.v-align-center {vertical-align: middle;}
.v-align-left {vertical-align: left;}

.mb-10 {margin-bottom: 10px;}

.table-block {
    @media(max-width: 1140px) {overflow-x: scroll;}
    table {
        border-collapse: collapse;/* Убираем двойные линии между ячейками */
        background: #fff; 
    }
    td, td {
            padding: 9px 15px 5px; /* Поля вокруг содержимого таблицы */
            border: 1px solid #ebebeb; /* Параметры рамки */
            font-family: 'OpenSans-Light';
            font-weight: 300 !important;
            font-size: 14px;
            line-height: 14px;
            color: #252525;
            vertical-align: middle;
    }
    th {
        padding: 9px 7px 5px;
        font-family: 'OpenSans-Bold';
        font-size: 14px;
        line-height: 14px;
        color: #Ffffff;
        background: #5199c9;
        border: 1px solid rgba(255, 255, 255, 0.65);
        vertical-align: middle;
        &.table-column-first {padding: 7px 17px 5px;}
    }
    &.table-block-search {
        td a, td{
            color: #22222a;
            & a:hover {color: #007dd7;}
        }
    }
}

.logo-blk {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media(max-width: 992px) {
        flex-direction: column;
        justify-content: center;
    }
    & .logo-big-img {
        width: 100%;
        max-width: 633px;
        @media(max-width: 992px) {margin-bottom: 30px;}
    }
    .logo-download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 90px;
        @media(max-width: 992px) {padding-left: 0;}
        & a {
            display: flex;
            align-items: center;
            justify-content: center; 
            font-family: 'OpenSans-Regular';
            font-size: 12px;
            line-height: 12px;
            text-decoration: underline;
            color: #585858;
            transition: 0.3s ease;
            &:not(:last-child) {margin-bottom: 17px;}
            &:hover {
                text-decoration: none;
                color: #3f43af;
            }
            & img {
                width: 37px;
                margin-right: 11px;
            }
        }
    }
}


.login-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding: 100px 0 100px;
    & form {width: 100%;}
    @media(max-width: 992px) { 
        padding: 50px 0;
    }
    & input {
        max-width: 100%;
        white-space: nowrap;
        &:not(:last-child) {margin-bottom: 12px;}
        &.btn {
            height: 47px;
            margin-top: 20px;
            text-transform: uppercase;
        }
    }
}