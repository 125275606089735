.footer {
    flex: 0 0 auto;
    padding: 15px 0 20px;
    background-color: #22222a;
    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        margin: 0 auto;
    }
    &-logo {
        width: 100%;
        max-width: 108px;
        & img {max-width: 100%;}
    }
    & .copy {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        line-height: 14px;
        color: #ffffff;
        @media (max-width: 500px) {
            font-size: 11px;
            line-height: 11px;
        }
        & img  {
            max-width: 112px;
            margin-left: 5px;
            @media (max-width: 500px) {
                max-width: 91px;
            }
        }
    }
}