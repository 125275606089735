.breadcrumps {
    display: flex;
    align-items: center;
		justify-content: flex-start;
		padding-bottom: 21px;
		@media(max-width: 992px) {display: none;}
    & a,
    & span {
		font-family: 'OpenSans-Regular';
		font-size: 14px;
			line-height: 14px;
			color: #585858;
			transition: 0.3s ease;
		}
		
		& a,
		& img {
			font-family: 'OpenSans-Bold';
			margin-right: 6px;
			&:hover {color: #007dd7;}
		}

		& img {
			width: 4px;
			height: 8px;
		}
}